import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/assets/talaryo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/menu/profil.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/navbar/Badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/navbar/Information.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/themes/themeSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/app/src/hooks/providers/AnalyticsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppContextProvider"] */ "/app/src/hooks/providers/AppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketContextProvider"] */ "/app/src/hooks/providers/socketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/providers/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/hooks/providers/UiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorToast"] */ "/app/src/utils/toaster.ts");

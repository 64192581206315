'use client';

import cn from '@/utils/cn';
import { useEffect, useState } from 'react';

const LogoSVG = ({ animate, className }: { animate?: boolean; className?: string } = {}) => {
  const [active, setActive] = useState<boolean>(false);
  useEffect(() => {
    if (animate) {
      setActive(true);
    }
  }, [animate]);

  return (
    <svg
      className={cn(className, { active: active && !className })}
      id={animate ? 'Tlogo' : ''}
      version="1.0"
      width="100%"
      height="100%"
      viewBox="0 0 1350 500"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(-20.000000,450.000000) scale(0.100000,-0.100000)"
        className={cn({ 'stroke-white': active && !className }, className)}
        strokeWidth="20"
      >
        <path
          d="M4630 2355 l0 -1405 415 0 415 0 0 230 0 230 -165 0 -165 0 0 1175 0
1175 -250 0 -250 0 0 -1405z"
          className="svg-elem-1"
        ></path>
        <path
          d="M450 3390 l0 -250 350 0 350 0 0 -1095 0 -1095 265 0 265 0 0 1095 0
1095 350 0 350 0 0 250 0 250 -965 0 -965 0 0 -250z"
          className="svg-elem-2"
        ></path>
        <path
          d="M12305 2979 c-250 -30 -467 -138 -640 -321 -108 -113 -176 -223 -221
-355 -44 -129 -56 -211 -55 -368 1 -305 101 -540 314 -741 136 -128 271 -202
465 -256 67 -19 104 -22 282 -22 191 0 212 2 304 27 366 99 647 382 739 747
31 124 31 387 -1 513 -29 117 -72 214 -137 312 -226 340 -622 515 -1050 464z
m284 -494 c187 -44 347 -202 396 -392 19 -73 19 -223 0 -296 -88 -339 -466
-502 -790 -343 -94 46 -185 137 -231 231 -162 329 2 706 347 799 71 19 198 19
278 1z"
          className="svg-elem-3"
        ></path>
        <path
          d="M2530 2705 l0 -235 535 0 535 0 0 -129 0 -129 -327 -4 c-368 -5 -386
-8 -533 -80 -67 -32 -102 -59 -171 -127 -141 -141 -184 -256 -177 -471 4 -104
9 -136 31 -193 64 -165 205 -308 370 -375 104 -42 189 -54 337 -49 209 7 332
54 465 178 l65 61 0 -101 0 -101 340 0 340 0 0 230 0 230 -115 0 -115 0 0 765
0 765 -790 0 -790 0 0 -235z m1068 -1043 c-3 -154 -4 -160 -31 -200 -60 -92
-169 -142 -323 -150 -137 -6 -188 11 -265 87 -45 45 -58 67 -68 108 -31 130
40 250 176 299 28 9 102 13 276 14 l238 0 -3 -158z"
          className="svg-elem-4"
        ></path>
        <path
          d="M5800 2705 l0 -235 535 0 535 0 0 -129 0 -129 -327 -4 c-368 -5 -386
-8 -533 -80 -67 -32 -102 -59 -171 -127 -141 -141 -184 -256 -177 -471 4 -104
9 -136 31 -193 64 -165 205 -308 370 -375 104 -42 189 -54 337 -49 209 7 332
54 465 178 l65 61 0 -101 0 -101 340 0 340 0 0 230 0 230 -115 0 -115 0 0 765
0 765 -790 0 -790 0 0 -235z m1068 -1043 c-3 -154 -4 -160 -31 -200 -60 -92
-169 -142 -323 -150 -137 -6 -188 11 -265 87 -45 45 -58 67 -68 108 -31 130
40 250 176 299 28 9 102 13 276 14 l238 0 -3 -158z"
          className="svg-elem-5"
        ></path>
        <path
          d="M7910 1945 l0 -995 250 0 250 0 0 750 0 750 290 0 290 0 0 245 0 245
-540 0 -540 0 0 -995z"
          className="svg-elem-6"
        ></path>
        <path
          d="M9183 2928 c3 -7 184 -443 402 -968 218 -525 399 -963 402 -972 3
-10 -23 -85 -58 -168 l-64 -150 -197 0 -198 0 0 -230 0 -230 373 0 372 0 564
1358 c310 746 566 1360 568 1365 2 4 -123 7 -279 7 l-283 0 -255 -671 c-139
-369 -257 -672 -260 -674 -4 -2 -122 298 -262 668 l-256 672 -287 3 c-229 2
-286 0 -282 -10z"
          className="svg-elem-7"
        ></path>
      </g>
    </svg>
  );
};
export default LogoSVG;
